import React, { useState, useEffect, useContext } from 'react'
import bootstrap from '../../assets/libraries/bootstrap/js/bootstrap'
import { api } from '../../utils/Api'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import DataContext from '../../utils/Context/DataContext'
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  Feather
} from '@expo/vector-icons'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import 'react-checkbox-tree/src/less/react-checkbox-tree.less'
let myModal = {}

const initial_value = {
  checked: [],
  addonexpanded: [],
  addon: {},
  original_addon_data: [],
  choosed_addon_data: [],
  sample_nodes: [
    {
      value: 'mars',
      label: 'Mars',
      children: [
        { value: 'phobos', label: 'Phobos' },
        { value: 'deimos', label: 'Deimos' },
      ],
    },
  ],
}

export default function ModalReviewAddon(props: any) {
  const context = useContext(DataContext)
  const [close_data, SetCloseData] = useState({
    action: 'close',
  })
  const [id_value, SetIdValue] = useState(useParams())
  const [save_data, SetSaveData] = useState(initial_value)
  const [is_edit, SetIsEdit] = useState(false)

  useEffect(() => {
    if (props.open) {
      console.log('ModalReviewAddon On Open :', props)
      myModal = new bootstrap.Modal(
        document.getElementById('ReviewAddonModal'),
        {},
      )
      SetSaveData((prevValue: any) => {
        // prevValue.addon = props.data.addon
        let addon_value = props.data.addon;
        addon_value.map((ele:any)=>{
          let split_value = ele.value.split("_")
          console.log("split_value :",split_value)
          let value_data = ele.value
          if(split_value.length == 1 ){
            ele.value = "children_" + value_data;
          }else{
            ele.value = value_data;
          }
        })
        prevValue.addon = addon_value;
        prevValue.original_addon_data = props.addon_data
        // if (props.data.type == 'view' || props.data.type == 'edit') {
        if (props.data.hasOwnProperty('checked')) {
          prevValue.checked = props.data.checked
        }
        // }

        console.log('ModalReviewAddon SetSaveData prevValue :', prevValue)
        return { ...prevValue }
      })
      myModal.show()
    }
  }, [props.open])

  useEffect(() => {
    console.log('ModalReviewAddon On mount :', props)
    let myModal1 = document.getElementById('ReviewAddonModal')
    myModal1.addEventListener('hidden.bs.modal', function (event) {
      SetSaveData(initial_value)
      props.close(close_data)
    })
  }, [])

  function onSubmit() {
    console.log('on submit save_data.checked :', save_data.checked)
    const state_checked = save_data.checked
    // if (state_checked.length > 0) {
    let pass_data = {
      index: props.index,
      addon: state_checked,
      is_checked: save_data.checked.length > 0 ? true : false,
    }
    SetSaveData((prevValue: any) => {
      prevValue.checked = []
      prevValue.addonexpanded = []
      return { ...prevValue }
    })
    let item = close_data
    item.action = 'save'
    item.value = pass_data
    SetCloseData(item)
    myModal.hide()
    // } else {
    //   console.log('Select addon')
    //   toast.error('Select addon')
    // }
  }

  function onCancel() {
    let item = close_data
    item.action = 'close'
    SetCloseData(item)
    myModal.hide()
  }

  function onAddonChange(checked : any, value_data : any) {
    SetSaveData((prevValue: any) => {
      prevValue.checked = checked
      return { ...prevValue }
    })

    // /////

    // let original_addon_data = save_data.original_addon_data;
    // let choosed_addon_data = save_data.choosed_addon_data;

    // console.log("checked :", checked);
    // console.log("value_data :", value_data);
    // console.log("original_addon_data :", original_addon_data);
    // console.log("choosed_addon_data :", choosed_addon_data);

    // if (value_data.checked) {
    //   console.log("On checked");

    //   if (value_data.children != undefined) {
    //     console.log("Have children only");
    //     let v1 = original_addon_data.find(
    //       (element) => element.value == value_data.value
    //     );
    //     choosed_addon_data.push(v1);
    //     SetSaveData((prevValue: any) => {
    //       prevValue.choosed_addon_data = choosed_addon_data;
    //       return { ...prevValue };
    //     });
    //   }

    //   if (Object.keys(value_data.parent).length != 0) {
    //     console.log("Have parent only");
    //     const parent_value = value_data.parent.value;
    //     const child_value = value_data.value;

    //     var parent_node = findNodeByValue(original_addon_data, parent_value);
    //     var selected_node = findNodeByValue(original_addon_data, child_value);

    //     console.log("parent_node :", parent_node);
    //     console.log("selected_node :", selected_node);
    //     parent_node.children = [];
    //     let v1 = choosed_addon_data.find(
    //       (element) => element.value == parent_node.value
    //     );
    //     console.log("V1 :", v1);
    //     if (v1) {
    //       console.log("Parent already available");
    //       let parent_index = choosed_addon_data.findIndex(
    //         (ele) => ele.value == parent_node.value
    //       );
    //       choosed_addon_data[parent_index].children.push(selected_node);
    //       // this.setState({ choosed_addon_data: choosed_addon_data });
    //       SetSaveData((prevValue: any) => {
    //         prevValue.choosed_addon_data = choosed_addon_data;
    //         return { ...prevValue };
    //       });
    //     } else {
    //       console.log("Parent not available");
    //       let data = {
    //         value: parent_node.value,
    //         children: [],
    //       };
    //       data.children.push(selected_node);
    //       choosed_addon_data.push(data);
    //       // this.setState({ choosed_addon_data: choosed_addon_data });
    //       SetSaveData((prevValue: any) => {
    //         prevValue.choosed_addon_data = choosed_addon_data;
    //         return { ...prevValue };
    //       });
    //     }
    //   }

    //   console.log("local choosed_addon_data :", choosed_addon_data);
    //   console.log("state choosed_addon_data :", save_data.choosed_addon_data);
    //   console.log("save data :", save_data);
    // }
  }

  function getParentsValue(data : any, value : any) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].value === value) {
        return [data[i].value]
      } else if (typeof data[i].children !== 'undefined') {
        const nodeInChild = getParentsValue(data[i].children, value)
        if (nodeInChild && nodeInChild.length > 0) {
          return [...nodeInChild, data[i].value]
        }
      }
    }
    return []
  }

  function findNodeByValue(data : any, value : any) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].value === value) {
        return data[i]
      } else if (typeof data[i].children !== 'undefined') {
        const nodeInChild = findNodeByValue(data[i].children, value)
        if (nodeInChild) {
          return nodeInChild
        }
      }
    }
    return
  }

  return (
    <div
      className="modal"
      id="ReviewAddonModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg  modal-fullscreen-sm-down modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              {props.data.type == 'add' ? 'Select Addon' : props.data.type == 'view'
                ? is_edit
                  ? 'Edit Addon'
                  : null
                : 'View Addon'}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data
                item.action = 'close'
                SetCloseData(item)
                myModal.hide()
              }}
            ></button>
          </div>
          {/* body */}
          <div className="modal-body">
            <div className="">
              <div className="d-flex">
                <p>Review Addon Modal</p>
                <div className="ms-auto">
                  {props.data.type == 'view' ? (
                    is_edit ? null : (
                      <button className="btn btn-sm text-primary"
                        onClick={() => {
                          SetIsEdit(true)
                        }}
                      >
                        <span className="pe-1">
                          <Feather name="edit-3"
                            size={16}
                            color="#297ad0"
                          />
                        </span>
                        Edit
                      </button>
                    )
                  ) : null}
                </div>
              </div>
              {save_data.addon.length > 0 ? (
                <CheckboxTree
                  expandOnClick
                  nodes={save_data.addon}
                  // checked={
                  //   props.data.type == 'view'
                  //     ? props.data.checked
                  //     : save_data.checked
                  // }
                  checked={save_data.checked}
                  disabled={
                    props.data.type == 'view' ? (is_edit ? false : true) : false
                  }
                  expanded={save_data.addonexpanded}
                  onCheck={(checked_value, value_data) => {
                    onAddonChange(checked_value, value_data)
                  }}
                  onExpand={(addonexpanded) => {
                    SetSaveData((prevValue: any) => {
                      prevValue.addonexpanded = addonexpanded
                      return { ...prevValue }
                    })
                  }}
                  icons={{
                    check: <i className="fas fa-check-square" />,
                    uncheck: <span className="fas fa-square" />,
                    halfCheck: <span className="fas fa-minus-square" />,
                    expandClose: <span className="fas fa-plus" />,
                    expandOpen: <span className="fas fa-minus" />,
                    expandAll: <span className="fas fa-minus-square" />,
                    collapseAll: <span className="fas fa-minus-square" />,
                    parentClose: <span className="fas fa-folder" />,
                    parentOpen: <span className="fas fa-folder-open" />,
                    leaf: <span className="fas fa-file" />,
                  }}
                />
              ) : null}
            </div>
          </div>
          {/* footer */}
          <div className="modal-footer shadow">
            {is_edit ? (
              <div className="d-flex align-items-center w-100 ">
                <button
                  type="button"
                  className="btn btn-secondary-outline btn-radius px-3"
                  onClick={() => {
                    SetIsEdit(false)
                  }}
                >
                  Cancel
                </button>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-primary btn-radius px-3"
                    onClick={() => {
                      onSubmit()
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center w-100">
                <button
                  type="button"
                  className="btn btn-secondary-outline btn-radius px-3"
                  onClick={() => {
                    let item = close_data
                    item.action = 'close'
                    SetCloseData(item)
                    myModal.hide()
                  }}
                >
                  Close
                </button>
                {props.data.type != 'view' ? (
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-primary btn-radius px-3"
                      onClick={() => {
                        onSubmit()
                      }}
                    >
                      Submit
                    </button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
